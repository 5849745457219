import { useParams } from "react-router";
import { useEffect, useState } from "react";
import Layout from "common/components/layout";
import axios from "axios";
import { ApiUrl } from "API/config";
import Loader from "common/components/loader";
function RatioAnalysis(props) {
  const operation = useParams().op;
  const params = useParams();
  const [data, setData] = useState();
  const [businessData, setBusinessData] = useState();
  const [productData, setProductData] = useState();
  const [sourcesOfFundData, setSourcesOfFundData] = useState({});
  const [fixedCostExcludingInterest, setFixedCostExcludingInterest] = useState({
    details: {
      gestPeriod: 0,
      1: 0,
      2: 0,
      3: 0,
      4: 0,
      5: 0,
      6: 0,
      7: 0,
      8: 0,
      9: 0,
      10: 0,
      11: 0,
      12: 0,
    },
    aggregate: {
      1: 0,
      2: 0,
      3: 0,
    }
  });
  const [fixedCostInterest, setFixedCostInterest] = useState({
    details: {
      gestPeriod: 0,
      1: 0,
      2: 0,
      3: 0,
      4: 0,
      5: 0,
      6: 0,
      7: 0,
      8: 0,
      9: 0,
      10: 0,
      11: 0,
      12: 0,
    },
    aggregate: {
      1: 0,
      2: 0,
      3: 0,
    }
  });
  const [netFixedAssets, setNetFixedAssets] = useState({
    details: {
      gestPeriod: 0,
      1: 0,
      2: 0,
      3: 0,
      4: 0,
      5: 0,
      6: 0,
      7: 0,
      8: 0,
      9: 0,
      10: 0,
      11: 0,
      12: 0,
    },
    aggregate: {
      1: 0,
      2: 0,
      3: 0,
    }
  });
  const [totalInflow, setTotalInflow] = useState({
    details: {
      gestPeriod: 0,
      1: 0,
      2: 0,
      3: 0,
      4: 0,
      5: 0,
      6: 0,
      7: 0,
      8: 0,
      9: 0,
      10: 0,
      11: 0,
      12: 0,
    },
    aggregate: {
      1: 0,
      2: 0,
      3: 0,
    }
  });
  const [inputCredit, setInputCredit] = useState({
    details: {
      gestPeriod: 0,
      1: 0,
      2: 0,
      3: 0,
      4: 0,
      5: 0,
      6: 0,
      7: 0,
      8: 0,
      9: 0,
      10: 0,
      11: 0,
      12: 0,
    },
    aggregate: {
      1: 0,
      2: 0,
      3: 0,
    }
  });
  const [startupCostData, setStartupCostData] = useState({});
  const [isLoader, setIsLoader] = useState(false);
  const [fixedCostCash, setFixedCostCash] = useState({
    details: {
      gestPeriod: 0,
      1: 0,
      2: 0,
      3: 0,
      4: 0,
      5: 0,
      6: 0,
      7: 0,
      8: 0,
      9: 0,
      10: 0,
      11: 0,
      12: 0,
    },
    aggregate: {
      1: 0,
      2: 0,
      3: 0,
    }
  });
  const [fixedCostNonCash, setFixedCostNonCash] = useState({
    details: {
      1: 0,
      2: 0,
      3: 0,
      4: 0,
      5: 0,
      6: 0,
      7: 0,
      8: 0,
      9: 0,
      10: 0,
      11: 0,
      12: 0,
    },
    aggregate: {
      1: 0,
      2: 0,
      3: 0,
    }
  });
  const [totalOutflow, setTotalOutflow] = useState({
    details: {
      gestPeriod: 0,
      1: 0,
      2: 0,
      3: 0,
      4: 0,
      5: 0,
      6: 0,
      7: 0,
      8: 0,
      9: 0,
      10: 0,
      11: 0,
      12: 0,
    },
    aggregate: {
      1: 0,
      2: 0,
      3: 0,
    }
  });
  const [additonalMachine, setAdditonalMachine] = useState({
    details: {
      gestPeriod: 0,
      1: 0,
      2: 0,
      3: 0,
      4: 0,
      5: 0,
      6: 0,
      7: 0,
      8: 0,
      9: 0,
      10: 0,
      11: 0,
      12: 0,
    },
    aggregate: {
      1: 0,
      2: 0,
      3: 0,
    }
  });
  const [subTotalVariableCost, setSubTotalVariableCost] = useState({
    details: {
      gestPeriod: 0,
      1: 0,
      2: 0,
      3: 0,
      4: 0,
      5: 0,
      6: 0,
      7: 0,
      8: 0,
      9: 0,
      10: 0,
      11: 0,
      12: 0,
    },
    aggregate: {
      1: 0,
      2: 0,
      3: 0,
    }
  });
  const [totalFixedExpense, setTotalFixedExpense] = useState({
    details: {
      gestPeriod: 0,
      1: 0,
      2: 0,
      3: 0,
      4: 0,
      5: 0,
      6: 0,
      7: 0,
      8: 0,
      9: 0,
      10: 0,
      11: 0,
      12: 0,
    },
    aggregate: {
      1: 0,
      2: 0,
      3: 0,
    }
  });
  const [subTotalAssetAcquisition, setSubTotalAssetAcquisition] = useState({
    details: {
      gestPeriod: 0,
      1: 0,
      2: 0,
      3: 0,
      4: 0,
      5: 0,
      6: 0,
      7: 0,
      8: 0,
      9: 0,
      10: 0,
      11: 0,
      12: 0,
    },
    aggregate: {
      1: 0,
      2: 0,
      3: 0,
    }
  });
  const [totalSalesRevenue, setTotalSalesRevenue] = useState({
    details: {
      1: 0,
      2: 0,
      3: 0,
      4: 0,
      5: 0,
      6: 0,
      7: 0,
      8: 0,
      9: 0,
      10: 0,
      11: 0,
      12: 0,
    },
    aggregate: {
      1: 0,
      2: 0,
      3: 0,
    }
  });
  const [totalVariableCost, setTotalVariableCost] = useState({
    details: {
      1: 0,
      2: 0,
      3: 0,
      4: 0,
      5: 0,
      6: 0,
      7: 0,
      8: 0,
      9: 0,
      10: 0,
      11: 0,
      12: 0,
    },
    aggregate: {
      1: 0,
      2: 0,
      3: 0,
    }
  });
  const [grossProfit, setGrossProfit] = useState({
    details: {
      1: 0,
      2: 0,
      3: 0,
      4: 0,
      5: 0,
      6: 0,
      7: 0,
      8: 0,
      9: 0,
      10: 0,
      11: 0,
      12: 0,
    },
    aggregate: {
      1: 0,
      2: 0,
      3: 0,
    }
  });
  const [netGSTOnSale, setNetGSTOnSale] = useState({
    details: {
      1: 0,
      2: 0,
      3: 0,
      4: 0,
      5: 0,
      6: 0,
      7: 0,
      8: 0,
      9: 0,
      10: 0,
      11: 0,
      12: 0,
    },
    aggregate: {
      1: 0,
      2: 0,
      3: 0,
    }
  });
  const [incometaxPaid, setIncometaxPaid] = useState({
    details: {
      1: 0,
      2: 0,
      3: 0,
      4: 0,
      5: 0,
      6: 0,
      7: 0,
      8: 0,
      9: 0,
      10: 0,
      11: 0,
      12: 0,
    },
    aggregate: {
      1: 0,
      2: 0,
      3: 0,
    }
  });
  const [taxPaid, setTaxPaid] = useState({
    details: {
      1: 0,
      2: 0,
      3: 0,
      4: 0,
      5: 0,
      6: 0,
      7: 0,
      8: 0,
      9: 0,
      10: 0,
      11: 0,
      12: 0,
    },
    aggregate: {
      1: 0,
      2: 0,
      3: 0,
    }
  });
  const [repayment, setRepayment] = useState({
    details: {
      1: 0,
      2: 0,
      3: 0,
      4: 0,
      5: 0,
      6: 0,
      7: 0,
      8: 0,
      9: 0,
      10: 0,
      11: 0,
      12: 0,
    },
    aggregate: {
      1: 0,
      2: 0,
      3: 0,
    }
  });

  const [totalNetGSTPaid, setTotalNetGSTPaid] = useState({
    details: {
      1: 0,
      2: 0,
      3: 0,
      4: 0,
      5: 0,
      6: 0,
      7: 0,
      8: 0,
      9: 0,
      10: 0,
      11: 0,
      12: 0,
    },
    aggregate: {
      1: 0,
      2: 0,
      3: 0,
    }
  });
  const [openingBalance, setOpeningBalance] = useState({
    details: {
      1: 0,
      2: 0,
      3: 0,
      4: 0,
      5: 0,
      6: 0,
      7: 0,
      8: 0,
      9: 0,
      10: 0,
      11: 0,
      12: 0,
    },
    aggregate: {
      1: 0,
      2: 0,
      3: 0,
    }
  });
  const [closingBalance, setClosingBalance] = useState({
    details: {
      1: 0,
      2: 0,
      3: 0,
      4: 0,
      5: 0,
      6: 0,
      7: 0,
      8: 0,
      9: 0,
      10: 0,
      11: 0,
      12: 0,
    },
    aggregate: {
      1: 0,
      2: 0,
      3: 0,
    }
  });

  const [accountsReceivable, setAccountsReceivable] = useState({
    details: {
      gestPeriod: 0,
      1: 0,
      2: 0,
      3: 0,
      4: 0,
      5: 0,
      6: 0,
      7: 0,
      8: 0,
      9: 0,
      10: 0,
      11: 0,
      12: 0,
    },
    aggregate: {
      1: 0,
      2: 0,
      3: 0,
    }
  });
  const [gstReceivable, setGstReceivable] = useState({
    details: {
      gestPeriod: 0,
      1: 0,
      2: 0,
      3: 0,
      4: 0,
      5: 0,
      6: 0,
      7: 0,
      8: 0,
      9: 0,
      10: 0,
      11: 0,
      12: 0,
    },
    aggregate: {
      1: 0,
      2: 0,
      3: 0,
    }
  });
  const [prepaidExpenses, setPrepaidExpenses] = useState({
    details: {
      gestPeriod: 0,
      1: 0,
      2: 0,
      3: 0,
      4: 0,
      5: 0,
      6: 0,
      7: 0,
      8: 0,
      9: 0,
      10: 0,
      11: 0,
      12: 0,
    },
    aggregate: {
      1: 0,
      2: 0,
      3: 0,
    }
  });
  const [accruedExpenses, setAccruedExpenses] = useState({
    details: {
      gestPeriod: 0,
      1: 0,
      2: 0,
      3: 0,
      4: 0,
      5: 0,
      6: 0,
      7: 0,
      8: 0,
      9: 0,
      10: 0,
      11: 0,
      12: 0,
    },
    aggregate: {
      1: 0,
      2: 0,
      3: 0,
    }
  });
  const [totalCurrentAssets, setTotalCurrentAssets] = useState({
    details: {
      gestPeriod: 0,
      1: 0,
      2: 0,
      3: 0,
      4: 0,
      5: 0,
      6: 0,
      7: 0,
      8: 0,
      9: 0,
      10: 0,
      11: 0,
      12: 0,
    },
    aggregate: {
      1: 0,
      2: 0,
      3: 0,
    }
  });
  const [totalAssets, setTotalAssets] = useState({
    details: {
      gestPeriod: 0,
      1: 0,
      2: 0,
      3: 0,
      4: 0,
      5: 0,
      6: 0,
      7: 0,
      8: 0,
      9: 0,
      10: 0,
      11: 0,
      12: 0,
    },
    aggregate: {
      1: 0,
      2: 0,
      3: 0,
    }
  });
  const [netGSTPayable, setNetGSTPayable] = useState({
    details: {
      gestPeriod: 0,
      1: 0,
      2: 0,
      3: 0,
      4: 0,
      5: 0,
      6: 0,
      7: 0,
      8: 0,
      9: 0,
      10: 0,
      11: 0,
      12: 0,
    },
    aggregate: {
      1: 0,
      2: 0,
      3: 0,
    }
  });
  const [incomeTax, setIncomeTax] = useState({
    details: {
      gestPeriod: 0,
      1: 0,
      2: 0,
      3: 0,
      4: 0,
      5: 0,
      6: 0,
      7: 0,
      8: 0,
      9: 0,
      10: 0,
      11: 0,
      12: 0,
    },
    aggregate: {
      1: 0,
      2: 0,
      3: 0,
    }
  });
  const [totalCurrentLiabilities, setTotalCurrentLiabilities] = useState({
    details: {
      gestPeriod: 0,
      1: 0,
      2: 0,
      3: 0,
      4: 0,
      5: 0,
      6: 0,
      7: 0,
      8: 0,
      9: 0,
      10: 0,
      11: 0,
      12: 0,
    },
    aggregate: {
      1: 0,
      2: 0,
      3: 0,
    }
  });
  const [otherLongTermDebt, setOtherLongTermDebt] = useState({
    details: {
      gestPeriod: 0,
      1: 0,
      2: 0,
      3: 0,
      4: 0,
      5: 0,
      6: 0,
      7: 0,
      8: 0,
      9: 0,
      10: 0,
      11: 0,
      12: 0,
    },
    aggregate: {
      1: 0,
      2: 0,
      3: 0,
    }
  });
  const [equityCapital, setEquityCapital] = useState({
    details: {
      gestPeriod: 0,
      1: 0,
      2: 0,
      3: 0,
      4: 0,
      5: 0,
      6: 0,
      7: 0,
      8: 0,
      9: 0,
      10: 0,
      11: 0,
      12: 0,
    },
    aggregate: {
      1: 0,
      2: 0,
      3: 0,
    }
  });
  const [profitAfterTax, setProfitAfterTax] = useState({
    details: {
      gestPeriod: 0,
      1: 0,
      2: 0,
      3: 0,
      4: 0,
      5: 0,
      6: 0,
      7: 0,
      8: 0,
      9: 0,
      10: 0,
      11: 0,
      12: 0,
    },
    aggregate: {
      1: 0,
      2: 0,
      3: 0,
    }
  });
  const [currentPortion, setCurrentPortion] = useState({
    details: {
      gestPeriod: 0,
      1: 0,
      2: 0,
      3: 0,
      4: 0,
      5: 0,
      6: 0,
      7: 0,
      8: 0,
      9: 0,
      10: 0,
      11: 0,
      12: 0,
    },
    aggregate: {
      1: 0,
      2: 0,
      3: 0,
    }
  });
  const [retainedEarnings, setRetainedEarnings] = useState({
    details: {
      gestPeriod: 0,
      1: 0,
      2: 0,
      3: 0,
      4: 0,
      5: 0,
      6: 0,
      7: 0,
      8: 0,
      9: 0,
      10: 0,
      11: 0,
      12: 0,
    },
    aggregate: {
      1: 0,
      2: 0,
      3: 0,
    }
  });
  const [commission, setCommission] = useState({
    details: {
      1: 0,
      2: 0,
      3: 0,
      4: 0,
      5: 0,
      6: 0,
      7: 0,
      8: 0,
      9: 0,
      10: 0,
      11: 0,
      12: 0,
    },
    aggregate: {
      1: 0,
      2: 0,
      3: 0,
    }
  });
  const [fgTransport, setFgTransport] = useState({
    details: {
      1: 0,
      2: 0,
      3: 0,
      4: 0,
      5: 0,
      6: 0,
      7: 0,
      8: 0,
      9: 0,
      10: 0,
      11: 0,
      12: 0,
    },
    aggregate: {
      1: 0,
      2: 0,
      3: 0,
    }
  });
  const [creditCardFee, setCreditCardFee] = useState({
    details: {
      gestPeriod: 0,
      1: 0,
      2: 0,
      3: 0,
      4: 0,
      5: 0,
      6: 0,
      7: 0,
      8: 0,
      9: 0,
      10: 0,
      11: 0,
      12: 0,
    },
    aggregate: {
      1: 0,
      2: 0,
      3: 0,
    }
  });
  const [accountsPayable, setAccountsPayable] = useState({
    details: {
      gestPeriod: 0,
      1: 0,
      2: 0,
      3: 0,
      4: 0,
      5: 0,
      6: 0,
      7: 0,
      8: 0,
      9: 0,
      10: 0,
      11: 0,
      12: 0,
    },
    aggregate: {
      1: 0,
      2: 0,
      3: 0,
    }
  });
    const [otherIncome, setOtherIncome] = useState({
      details: {
        gestPeriod: 0,
        1: 0,
        2: 0,
        3: 0,
        4: 0,
        5: 0,
        6: 0,
        7: 0,
        8: 0,
        9: 0,
        10: 0,
        11: 0,
        12: 0,
      },
      aggregate: {
        1: 0,
        2: 0,
        3: 0,
      }
    });
    const [materialInventory, setMaterialInventory] = useState({
      details: {
        gestPeriod: 0,
        1: 0,
        2: 0,
        3: 0,
        4: 0,
        5: 0,
        6: 0,
        7: 0,
        8: 0,
        9: 0,
        10: 0,
        11: 0,
        12: 0,
      },
      aggregate: {
        1: 0,
        2: 0,
        3: 0,
      }
    });
    const [finishedGoodsInventory, setFinishedGoodsInventory] = useState({
      details: {
        gestPeriod: 0,
        1: 0,
        2: 0,
        3: 0,
        4: 0,
        5: 0,
        6: 0,
        7: 0,
        8: 0,
        9: 0,
        10: 0,
        11: 0,
        12: 0,
      },
      aggregate: {
        1: 0,
        2: 0,
        3: 0,
      }
    });
    const [inventoryTurnover, setInventoryTurnover] = useState({
      details: {
        gestPeriod: 0,
        1: 0,
        2: 0,
        3: 0,
        4: 0,
        5: 0,
        6: 0,
        7: 0,
        8: 0,
        9: 0,
        10: 0,
        11: 0,
        12: 0,
      },
      aggregate: {
        1: 0,
        2: 0,
        3: 0,
      }
    });
    const [totalFunding, setTotalFunding] = useState(0);






  let userData = localStorage.getItem("user_data");
  let userObj = userData ? JSON.parse(localStorage.getItem("user_data")).user : undefined;
  const getNumericFormat = (num) => {
    const numberFormatter = Intl.NumberFormat("en-IN", {
      maximumFractionDigits: 0,
    });
    return num ? numberFormatter.format(num) : 0;
  };
  useEffect(() => {
    if (operation == "Edit") {
      setIsLoader(true);
      axios
        .get(`${ApiUrl}protege/${params.id}`)
        .then((data) => {

          setData(data.data[0].personalInfoId);
          setBusinessData(data.data[0].businessInfoid);
          setStartupCostData(data.data[0].startupCost ? data.data[0].startupCost : {});
          setSourcesOfFundData(data.data[0].sourcesOfFund ? data.data[0].sourcesOfFund : {});
         
          setIsLoader(false);
          let productValue;
          let businessTypes=[];
          if (data.data[0].manufacturingIds) {
            businessTypes.push(data.data[0].manufacturingIds)
          } 
           if (data.data[0].trading) {
            businessTypes.push(data.data[0].trading)
          } 
           if (data.data[0].service) {
            businessTypes.push(data.data[0].service)
          } 
           if (data.data[0].animalhusbandry) {
            businessTypes.push(data.data[0].animalhusbandry)
          } 
           if (data.data[0].agro) {
            businessTypes.push(data.data[0].agro)
          } 
          console.log(businessTypes)
        
          const combinedValue = {
            orderingFrequency: { fieldOrderingFrequencyName: [] },
            productList: [],
            rawMaterialConsumption: { rawMaterial: [] },
            rawMaterialProcurement: {
              fieldProductRMName:[],
              initialInputCost:0,
              initialInputCostGST:0,
              monthlyInputGSTPaid:{ 
                1: 0,
                2: 0,
                3: 0,
                4: 0,
                5: 0,
                6: 0,
                7: 0,
                8: 0,
                9: 0,
                10: 0,
                11: 0,
                12: 0,
              },
              monthlyInputPurchaseB4GST:{
                 1: 0,
                2: 0,
                3: 0,
                4: 0,
                5: 0,
                6: 0,
                7: 0,
                8: 0,
                9: 0,
                10: 0,
                11: 0,
                12: 0,
              },
              yearlyInputGSTPaid:{ 
                 1: 0,
                2: 0,
                3: 0,
              },
              yearlyInputPurchaseB4GST:{ 
                 1: 0,
                2: 0,
                3: 0,
              } 
            },
            salesProjection: {
              fieldProductSkuName:[],
              fieldProductsName:[],
              totalMonthlyCashInflowOfSales:{
                1: 0,
                2: 0,
                3: 0,
                4: 0,
                5: 0,
                6: 0,
                7: 0,
                8: 0,
                9: 0,
                10: 0,
                11: 0,
                12: 0,
              },
              totalYearlyCashInFlowOfSales:{
                1: 0,
                2: 0,
                3: 0,
              },
              totalMonthlyCashInflowOfSalesGST:{
                1: 0,
                2: 0,
                3: 0,
                4: 0,
                5: 0,
                6: 0,
                7: 0,
                8: 0,
                9: 0,
                10: 0,
                11: 0,
                12: 0,
              },
              totalYearlyCashInFlowOfSalesGST:{
                1: 0,
                2: 0,
                3: 0,
              },
              totalMonthlyGSTCollectOnSale:{
                1: 0,
                2: 0,
                3: 0,
                4: 0,
                5: 0,
                6: 0,
                7: 0,
                8: 0,
                9: 0,
                10: 0,
                11: 0,
                12: 0,
              },
              totalYearlyGSTCollectOnSale:{
                1: 0,
                2: 0,
                3: 0,
              },
              totalMonthlySalesRevenue:{
                1: 0,
                2: 0,
                3: 0,
                4: 0,
                5: 0,
                6: 0,
                7: 0,
                8: 0,
                9: 0,
                10: 0,
                11: 0,
                12: 0,
              },
              totalYearlySalesRevenue:{
                1: 0,
                2: 0,
                3: 0,
              },
              totalMonthlyValues:{
                details:{
                  1: {
                    quantity: 0,
                    prod_quantity: 0,
                    closing_Stock: 0,
                    salesRevenue: 0,
                    commission: 0,
                    transport: 0,
                    variableCostB4GST: 0,
                    variableCostGST: 0,
                    gst_collected_on_sales: 0,
                  },
                  2: {
                    quantity: 0,
                    prod_quantity: 0,
                    closing_Stock: 0,
                    salesRevenue: 0,
                    commission: 0,
                    transport: 0,
                    variableCostB4GST: 0,
                    variableCostGST: 0,
                    gst_collected_on_sales: 0,
                  },
                  3: {
                    quantity: 0,
                    prod_quantity: 0,
                    closing_Stock: 0,
                    salesRevenue: 0,
                    commission: 0,
                    transport: 0,
                    variableCostB4GST: 0,
                    variableCostGST: 0,
                    gst_collected_on_sales: 0,
                  },
                  4: {
                    quantity: 0,
                    prod_quantity: 0,
                    closing_Stock: 0,
                    salesRevenue: 0,
                    commission: 0,
                    transport: 0,
                    variableCostB4GST: 0,
                    variableCostGST: 0,
                    gst_collected_on_sales: 0,
                  },
                  5: {
                    quantity: 0,
                    prod_quantity: 0,
                    closing_Stock: 0,
                    salesRevenue: 0,
                    commission: 0,
                    transport: 0,
                    variableCostB4GST: 0,
                    variableCostGST: 0,
                    gst_collected_on_sales: 0,
                  },
                  6: {
                    quantity: 0,
                    prod_quantity: 0,
                    closing_Stock: 0,
                    salesRevenue: 0,
                    commission: 0,
                    transport: 0,
                    variableCostB4GST: 0,
                    variableCostGST: 0,
                    gst_collected_on_sales: 0,
                  },
                  7: {
                    quantity: 0,
                    prod_quantity: 0,
                    closing_Stock: 0,
                    salesRevenue: 0,
                    commission: 0,
                    transport: 0,
                    variableCostB4GST: 0,
                    variableCostGST: 0,
                    gst_collected_on_sales: 0,
                  },
                  8: {
                    quantity: 0,
                    prod_quantity: 0,
                    closing_Stock: 0,
                    salesRevenue: 0,
                    commission: 0,
                    transport: 0,
                    variableCostB4GST: 0,
                    variableCostGST: 0,
                    gst_collected_on_sales: 0,
                  },
                  9: {
                    quantity: 0,
                    prod_quantity: 0,
                    closing_Stock: 0,
                    salesRevenue: 0,
                    commission: 0,
                    transport: 0,
                    variableCostB4GST: 0,
                    variableCostGST: 0,
                    gst_collected_on_sales: 0,
                  },
                  10: {
                    quantity: 0,
                    prod_quantity: 0,
                    closing_Stock: 0,
                    salesRevenue: 0,
                    commission: 0,
                    transport: 0,
                    variableCostB4GST: 0,
                    variableCostGST: 0,
                    gst_collected_on_sales: 0,
                  },
                  11: {
                    quantity: 0,
                    prod_quantity: 0,
                    closing_Stock: 0,
                    salesRevenue: 0,
                    commission: 0,
                    transport: 0,
                    variableCostB4GST: 0,
                    variableCostGST: 0,
                    gst_collected_on_sales: 0,
                  },
                  12: {
                    quantity: 0,
                    prod_quantity: 0,
                    closing_Stock: 0,
                    salesRevenue: 0,
                    commission: 0,
                    transport: 0,
                    variableCostB4GST: 0,
                    variableCostGST: 0,
                    gst_collected_on_sales: 0,
                  },
                
                }, 
                aggregate:{
                  1: {
                    quantity: 0,
                    prod_quantity: 0,
                    closing_Stock: 0,
                    salesRevenue: 0,
                    commission: 0,
                    transport: 0,
                    variableCostB4GST: 0,
                    variableCostGST: 0,
                    gst_collected_on_sales: 0,
                  },
                  2: {
                    quantity: 0,
                    prod_quantity: 0,
                    closing_Stock: 0,
                    salesRevenue: 0,
                    commission: 0,
                    transport: 0,
                    variableCostB4GST: 0,
                    variableCostGST: 0,
                    gst_collected_on_sales: 0,
                  },
                  3: {
                    quantity: 0,
                    prod_quantity: 0,
                    closing_Stock: 0,
                    salesRevenue: 0,
                    commission: 0,
                    transport: 0,
                    variableCostB4GST: 0,
                    variableCostGST: 0,
                    gst_collected_on_sales: 0,
                  },
                }

              },
              creditCardFee:{
                details:{
                  1: 0,
                  2: 0,
                  3: 0,
                  4: 0,
                  5: 0,
                  6: 0,
                  7: 0,
                  8: 0,
                  9: 0,
                  10: 0,
                  11: 0,
                  12: 0,
                }, 
                aggregate:{
                  1: 0,
                  2: 0,
                  3: 0,
                },
              },
              creditCardFeeCashInFlow:{
                details:{
                  1: 0,
                  2: 0,
                  3: 0,
                  4: 0,
                  5: 0,
                  6: 0,
                  7: 0,
                  8: 0,
                  9: 0,
                  10: 0,
                  11: 0,
                  12: 0,
                }, 
                aggregate:{
                  1: 0,
                  2: 0,
                  3: 0,
                },
              }

            },
          };
          
          for (let type = 0; type < businessTypes.length; type++) {
          
            //orderFrequency
            combinedValue.orderingFrequency.fieldOrderingFrequencyName = [
              ...(combinedValue?.orderingFrequency?.fieldOrderingFrequencyName ?? []),
              ...(businessTypes?.[type]?.orderingFrequency?.fieldOrderingFrequencyName ?? []),
            ];

            //productList
            combinedValue.productList = [...(combinedValue?.productList), ...businessTypes[type]?.productList];

            //consumption
            combinedValue.rawMaterialConsumption.rawMaterial = [
              ...(combinedValue?.rawMaterialConsumption?.rawMaterial ?? []),
              ...(businessTypes[type].rawMaterialConsumption?.rawMaterial ?? []),
            ];

            //procurement
            combinedValue.rawMaterialProcurement.fieldProductRMName = [
              ...(combinedValue?.rawMaterialProcurement?.fieldProductRMName ?? []),
              ...(businessTypes[type].rawMaterialProcurement?.fieldProductRMName ?? []),
            ];
            combinedValue.rawMaterialProcurement.initialInputCost += businessTypes[type].rawMaterialProcurement?.initialInputCost?parseFloat(businessTypes[type].rawMaterialProcurement?.initialInputCost):0;
            combinedValue.rawMaterialProcurement.initialInputCostGST += businessTypes[type].rawMaterialProcurement?.initialInputCostGST?parseFloat(businessTypes[type].rawMaterialProcurement?.initialInputCostGST):0;
            for(let month=1; month<=12;month++){
                combinedValue.rawMaterialProcurement.monthlyInputGSTPaid[month] += businessTypes[type].rawMaterialProcurement?.monthlyInputGSTPaid?.[month]?parseFloat(businessTypes[type].rawMaterialProcurement?.monthlyInputGSTPaid?.[month]):0;
                combinedValue.rawMaterialProcurement.monthlyInputPurchaseB4GST[month] += businessTypes[type].rawMaterialProcurement?.monthlyInputPurchaseB4GST?.[month]?parseFloat(businessTypes[type].rawMaterialProcurement?.monthlyInputPurchaseB4GST?.[month]):0;
            }
            for(let year=1; year<=3;year++){
                combinedValue.rawMaterialProcurement.yearlyInputGSTPaid[year] += businessTypes[type].rawMaterialProcurement?.yearlyInputGSTPaid?.[year]?parseFloat(businessTypes[type].rawMaterialProcurement?.yearlyInputGSTPaid?.[year]):0;
                combinedValue.rawMaterialProcurement.yearlyInputPurchaseB4GST[year] += businessTypes[type].rawMaterialProcurement?.yearlyInputPurchaseB4GST?.[year]?parseFloat(businessTypes[type].rawMaterialProcurement?.yearlyInputPurchaseB4GST?.[year]):0;
            }
            
            //salesProjection
            combinedValue.salesProjection.fieldProductSkuName = [
              ...(combinedValue?.salesProjection?.fieldProductSkuName ?? []),
              ...(businessTypes[type].salesProjection?.fieldProductSkuName ?? []),
            ];
            combinedValue.salesProjection.fieldProductsName = [
              ...(combinedValue?.salesProjection?.fieldProductsName ?? []),
              ...(businessTypes[type].salesProjection?.fieldProductsName ?? []),
            ];

            for(let month=1; month<=12;month++){
              combinedValue.salesProjection.totalMonthlyCashInflowOfSales[month] += businessTypes[type].salesProjection?.totalMonthlyCashInflowOfSales?.[month]?parseFloat(businessTypes[type].salesProjection?.totalMonthlyCashInflowOfSales?.[month]):0;
              combinedValue.salesProjection.totalMonthlyCashInflowOfSalesGST[month] += businessTypes[type].salesProjection?.totalMonthlyCashInflowOfSalesGST?.[month]?parseFloat(businessTypes[type].salesProjection?.totalMonthlyCashInflowOfSalesGST?.[month]):0;
              combinedValue.salesProjection.totalMonthlyGSTCollectOnSale[month] += businessTypes[type].salesProjection?.totalMonthlyGSTCollectOnSale?.[month]?parseFloat(businessTypes[type].salesProjection?.totalMonthlyGSTCollectOnSale?.[month]):0;
              combinedValue.salesProjection.totalMonthlySalesRevenue[month] += businessTypes[type].salesProjection?.totalMonthlySalesRevenue?.[month]?parseFloat(businessTypes[type].salesProjection?.totalMonthlySalesRevenue?.[month]):0;
              combinedValue.salesProjection.totalMonthlyValues.details[month].commission += businessTypes[type].salesProjection?.totalMonthlyValues?.details?.[month]?.commission?parseFloat(businessTypes[type].salesProjection?.totalMonthlyValues.details[month].commission):0;
              combinedValue.salesProjection.totalMonthlyValues.details[month].quantity += businessTypes[type].salesProjection?.totalMonthlyValues.details[month].quantity?parseFloat(businessTypes[type].salesProjection?.totalMonthlyValues.details[month].quantity):0;
              combinedValue.salesProjection.totalMonthlyValues.details[month].prod_quantity += businessTypes[type].salesProjection?.totalMonthlyValues.details[month].prod_quantity?parseFloat(businessTypes[type].salesProjection?.totalMonthlyValues.details[month].prod_quantity):0;
              combinedValue.salesProjection.totalMonthlyValues.details[month].closing_Stock += businessTypes[type].salesProjection?.totalMonthlyValues.details[month].closing_Stock?parseFloat(businessTypes[type].salesProjection?.totalMonthlyValues.details[month].closing_Stock):0;
              combinedValue.salesProjection.totalMonthlyValues.details[month].salesRevenue += businessTypes[type].salesProjection?.totalMonthlyValues.details[month].salesRevenue?parseFloat(businessTypes[type].salesProjection?.totalMonthlyValues.details[month].salesRevenue):0;
              combinedValue.salesProjection.totalMonthlyValues.details[month].transport += businessTypes[type].salesProjection?.totalMonthlyValues.details[month].transport?parseFloat(businessTypes[type].salesProjection?.totalMonthlyValues.details[month].transport):0;
              combinedValue.salesProjection.totalMonthlyValues.details[month].variableCostB4GST += businessTypes[type].salesProjection?.totalMonthlyValues.details[month].variableCostB4GST?parseFloat(businessTypes[type].salesProjection?.totalMonthlyValues.details[month].variableCostB4GST):0;
              combinedValue.salesProjection.totalMonthlyValues.details[month].variableCostGST += businessTypes[type].salesProjection?.totalMonthlyValues.details[month].variableCostGST?parseFloat(businessTypes[type].salesProjection?.totalMonthlyValues.details[month].variableCostGST):0;
              combinedValue.salesProjection.totalMonthlyValues.details[month].gst_collected_on_sales += businessTypes[type].salesProjection?.totalMonthlyValues.details[month].gst_collected_on_sales?parseFloat(businessTypes[type].salesProjection?.totalMonthlyValues.details[month].gst_collected_on_sales):0;
              combinedValue.salesProjection.creditCardFee.details[month] += businessTypes[type].salesProjection?.creditCardFee?.details?.[month]?parseFloat( businessTypes[type].salesProjection?.creditCardFee.details[month]):0;
              combinedValue.salesProjection.creditCardFeeCashInFlow.details[month] += businessTypes[type]?.salesProjection?.creditCardFeeCashInFlow?.details?.[month]?parseFloat( businessTypes[type].salesProjection?.creditCardFeeCashInFlow.details[month]):0;
            }
            for(let year=1; year<=3;year++){
              combinedValue.salesProjection.totalYearlyCashInFlowOfSales[year] += businessTypes[type].salesProjection?.totalYearlyCashInFlowOfSales?.[year] ? parseFloat(businessTypes[type].salesProjection?.totalYearlyCashInFlowOfSales?.[year]) : 0;
              combinedValue.salesProjection.totalYearlyCashInFlowOfSalesGST[year] += businessTypes[type].salesProjection?.totalYearlyCashInFlowOfSalesGST?.[year] ? parseFloat(businessTypes[type].salesProjection?.totalYearlyCashInFlowOfSalesGST?.[year]) : 0;
              combinedValue.salesProjection.totalYearlyGSTCollectOnSale[year] += businessTypes[type].salesProjection?.totalYearlyGSTCollectOnSale?.[year] ? parseFloat(businessTypes[type].salesProjection?.totalYearlyGSTCollectOnSale?.[year]) : 0;
              combinedValue.salesProjection.totalYearlySalesRevenue[year] += businessTypes[type].salesProjection?.totalYearlySalesRevenue?.[year] ? parseFloat(businessTypes[type].salesProjection?.totalYearlySalesRevenue?.[year]) : 0;
              combinedValue.salesProjection.totalMonthlyValues.aggregate[year].commission += businessTypes[type].salesProjection?.totalMonthlyValues.aggregate[year].commission ? parseFloat(businessTypes[type].salesProjection?.totalMonthlyValues.aggregate[year].commission) : 0;
              combinedValue.salesProjection.totalMonthlyValues.aggregate[year].quantity += businessTypes[type].salesProjection?.totalMonthlyValues.aggregate[year].quantity ? parseFloat(businessTypes[type].salesProjection?.totalMonthlyValues.aggregate[year].quantity) : 0;
              combinedValue.salesProjection.totalMonthlyValues.aggregate[year].prod_quantity += businessTypes[type].salesProjection?.totalMonthlyValues.aggregate[year].prod_quantity ? parseFloat(businessTypes[type].salesProjection?.totalMonthlyValues.aggregate[year].prod_quantity) : 0;
              combinedValue.salesProjection.totalMonthlyValues.aggregate[year].closing_Stock += businessTypes[type].salesProjection?.totalMonthlyValues.aggregate[year].closing_Stock ? parseFloat(businessTypes[type].salesProjection?.totalMonthlyValues.aggregate[year].closing_Stock) : 0;
              combinedValue.salesProjection.totalMonthlyValues.aggregate[year].salesRevenue += businessTypes[type].salesProjection?.totalMonthlyValues.aggregate[year].salesRevenue ? parseFloat(businessTypes[type].salesProjection?.totalMonthlyValues.aggregate[year].salesRevenue) : 0;
              combinedValue.salesProjection.totalMonthlyValues.aggregate[year].transport += businessTypes[type].salesProjection?.totalMonthlyValues.aggregate[year].transport ? parseFloat(businessTypes[type].salesProjection?.totalMonthlyValues.aggregate[year].transport) : 0;
              combinedValue.salesProjection.totalMonthlyValues.aggregate[year].variableCostB4GST += businessTypes[type].salesProjection?.totalMonthlyValues.aggregate[year].variableCostB4GST ? parseFloat(businessTypes[type].salesProjection?.totalMonthlyValues.aggregate[year].variableCostB4GST) : 0;
              combinedValue.salesProjection.totalMonthlyValues.aggregate[year].variableCostGST += businessTypes[type].salesProjection?.totalMonthlyValues.aggregate[year].variableCostGST ? parseFloat(businessTypes[type].salesProjection?.totalMonthlyValues.aggregate[year].variableCostGST) : 0;
              combinedValue.salesProjection.totalMonthlyValues.aggregate[year].gst_collected_on_sales += businessTypes[type].salesProjection?.totalMonthlyValues.aggregate[year].gst_collected_on_sales ? parseFloat(businessTypes[type].salesProjection?.totalMonthlyValues.aggregate[year].gst_collected_on_sales) : 0;
              combinedValue.salesProjection.creditCardFee.aggregate[year] += businessTypes[type]?.salesProjection?.creditCardFee?.aggregate?.[year]?parseFloat( businessTypes[type].salesProjection?.creditCardFee.aggregate[year]):0;
              combinedValue.salesProjection.creditCardFeeCashInFlow.aggregate[year] += businessTypes[type]?.salesProjection?.creditCardFeeCashInFlow?.aggregate?.[year]?parseFloat( businessTypes[type].salesProjection?.creditCardFeeCashInFlow.aggregate[year]):0;
           
            }

          }
          productValue= combinedValue;
          setProductData(productValue);
         

          let cashExcludingInterest = fixedCostExcludingInterest;
          let cashInterest = fixedCostInterest;
          let totalCash = fixedCostCash;
          let salesCommission = commission;
          let transportTotal = fgTransport;
          let totalFixedCost = totalFixedExpense;
          let nonCash = fixedCostNonCash;
          let totalFundingValue = totalFunding;
          data.data[0].fixedCost.fieldLineItemsName.filter((x, index) => {
            cashExcludingInterest.details['gestPeriod'] += x.lineItemName !== "Dep + Amort (Non Cash)" && x.lineItemName !== "Addtnl Dep (Non Cash)" && x.lineItemName !== "Interest" ? parseFloat(x.details['gestPeriod']) : 0;
            cashInterest.details['gestPeriod'] += x.lineItemName == "Interest" ? parseFloat(x.details['gestPeriod']) : 0;
            totalCash.details['gestPeriod'] += x.lineItemName !== "Dep + Amort (Non Cash)" && x.lineItemName !== "Addtnl Dep (Non Cash)" ? (x.details['gestPeriod'] !== null && !isNaN(parseFloat(x.details['gestPeriod'])) ? parseFloat(x.details['gestPeriod']) : 0) : 0;
            for (let i = 1; i <= 12; i++) {
              cashExcludingInterest.details[i] += x.lineItemName !== "Dep + Amort (Non Cash)" && x.lineItemName !== "Addtnl Dep (Non Cash)" && x.lineItemName !== "Interest" ? parseFloat(x.details[i]) : 0;
              cashInterest.details[i] += x.lineItemName == "Interest" ? parseFloat(x.details[i]) : 0;
              totalCash.details[i] += x.lineItemName !== "Dep + Amort (Non Cash)" && x.lineItemName !== "Addtnl Dep (Non Cash)" ? parseFloat(x.details[i]) : 0;
              nonCash.details[i] += x.lineItemName == "Dep + Amort (Non Cash)" || x.lineItemName == "Addtnl Dep (Non Cash)" ? parseFloat(x.details[i]) : 0;
              totalFixedCost.details[i] += parseFloat(x.details[i]);
            }
            totalFixedCost.details['gestPeriod'] += parseFloat(x.details['gestPeriod']);
            for (let i = 1; i <= 3; i++) {
              cashExcludingInterest.aggregate[i] += x.lineItemName !== "Dep + Amort (Non Cash)" && x.lineItemName !== "Addtnl Dep (Non Cash)" && x.lineItemName !== "Interest" ? parseFloat(x.aggregate[i]) : 0;
              cashInterest.aggregate[i] += x.lineItemName == "Interest" ? parseFloat(x.aggregate[i]) : 0;
              totalCash.aggregate[i] += x.lineItemName !== "Dep + Amort (Non Cash)" && x.lineItemName !== "Addtnl Dep (Non Cash)" ? parseFloat(x.aggregate[i]) : 0;
              nonCash.aggregate[i] += x.lineItemName == "Dep + Amort (Non Cash)" || x.lineItemName == "Addtnl Dep (Non Cash)" ? parseFloat(x.aggregate[i]) : 0;
              totalFixedCost.aggregate[i] += parseFloat(x.aggregate[i]);
            }
          });

          totalFundingValue = (data?.data?.[0]?.sourcesOfFund?.fieldLineItemsName
            ?.filter(item => 
                (item?.lineItemName !== "Own Fund") && 
                !(item?.lineItemName === "Family/friends/relatives" && parseInt(item['Repayment Terms (Months)']) === 0)
            )
            ?.reduce((total, item) => total + parseFloat(item?.Amount || 0), 0));
          setFixedCostExcludingInterest(cashExcludingInterest);
          setFixedCostInterest(cashInterest);
          setFixedCostCash(totalCash);
          setFixedCostNonCash(nonCash);
          setTotalFixedExpense(totalFixedCost);
         setTotalFunding(totalFundingValue);
            
          const whichMonth = parseFloat(data?.data?.[0]?.sourcesOfFund?.whichMonth)
          let other = {
            details: {
              gestPeriod: 0,
              1: 0,
              2: 0,
              3: 0,
              4: 0,
              5: 0,
              6: 0,
              7: 0,
              8: 0,
              9: 0,
              10: 0,
              11: 0,
              12: 0,
            },
            aggregate: {
              1: 0,
              2: 0,
              3: 0,
            }
          };
          if(whichMonth>0){
           
            if(whichMonth<=12){

              other.details[whichMonth] = parseFloat(data?.data?.[0]?.sourcesOfFund?.whichMachine)
              other.aggregate[1] = parseFloat(data?.data?.[0]?.sourcesOfFund?.whichMachine)
            }else if(whichMonth<=24){
              other.aggregate[2] = parseFloat(data?.data?.[0]?.sourcesOfFund?.whichMachine)
            }else if(whichMonth<=36){
              other.aggregate[3] = parseFloat(data?.data?.[0]?.sourcesOfFund?.whichMachine)

            }
            setOtherIncome(other)
          }
          let accountsPayableCalc={
            details: {
              gestPeriod: 0,
              1: 0,
              2: 0,
              3: 0,
              4: 0,
              5: 0,
              6: 0,
              7: 0,
              8: 0,
              9: 0,
              10: 0,
              11: 0,
              12: 0,
            },
            aggregate: {
              1: 0,
              2: 0,
              3: 0,
            }
          }
          for (let i = 1; i <= 12; i++) {
            for (let j = 0; j < productValue?.rawMaterialProcurement?.fieldProductRMName.length; j++) {
              let receipt =(parseFloat(productValue?.rawMaterialProcurement?.fieldProductRMName[j]?.ReceiptPlan?.details?.receipt?.[i]))*(productValue?.rawMaterialProcurement?.fieldProductRMName[j]?.numberOfItemsLessThan===false?1:(parseFloat(productValue?.rawMaterialProcurement?.fieldProductRMName[j]?.per_unit_cost_of_raw_material)));
             
                accountsPayableCalc.details[i] += parseFloat(receipt);
            }
          }

          for (let i = 1; i <= 3; i++) {
            for (let j = 0; j < productValue?.rawMaterialProcurement?.fieldProductRMName.length; j++) {
              let receipt = parseFloat(productValue?.rawMaterialProcurement?.fieldProductRMName[j]?.ReceiptPlan?.aggregate?.receipt?.[i])*(productValue?.rawMaterialProcurement?.fieldProductRMName[j]?.numberOfItemsLessThan===false?1:(parseFloat(productValue?.rawMaterialProcurement?.fieldProductRMName[j]?.per_unit_cost_of_raw_material)));
                accountsPayableCalc.aggregate[i] += parseFloat(receipt);
              
            }
          
            
          }

          var totalVariableCost={
            details: {
              gestPeriod: 0,
              1: 0,
              2: 0,
              3: 0,
              4: 0,
              5: 0,
              6: 0,
              7: 0,
              8: 0,
              9: 0,
              10: 0,
              11: 0,
              12: 0,
            },
            aggregate: {
              1: 0,
              2: 0,
              3: 0,
            }
          }
         
        let prodList = productValue?.salesProjection?.fieldProductSkuName || [];
          let mat={
            details: {
              gestPeriod: 0,
              1: 0,
              2: 0,
              3: 0,
              4: 0,
              5: 0,
              6: 0,
              7: 0,
              8: 0,
              9: 0,
              10: 0,
              11: 0,
              12: 0,
            },
            aggregate: {
              1: 0,
              2: 0,
              3: 0,
            }
          };
          let fin={
            details: {
              gestPeriod: 0,
              1: 0,
              2: 0,
              3: 0,
              4: 0,
              5: 0,
              6: 0,
              7: 0,
              8: 0,
              9: 0,
              10: 0,
              11: 0,
              12: 0,
            },
            aggregate: {
              1: 0,
              2: 0,
              3: 0,
            }
          };

          for (let prod = 0; prod < prodList?.length; prod++) {
            // Traverse productValue to find the matching product's numberOfItemsLessThan
            let matchingProduct = productValue?.rawMaterialProcurement?.fieldProductRMName?.find(item => item.raw_material_description == prodList[prod]?.name);
            let isLessThan = matchingProduct?.numberOfItemsLessThan;
        

            console.log(prodList[prod]);
            for (let i = 1; i <= 12; i++) {
        
                let quantity = isLessThan === false ? 1 : parseFloat(prodList[prod]?.details[i]?.quantity);
                let prodQuantity = isLessThan === false ? 1 : parseFloat(prodList[prod]?.details[i]?.prod_quantity);
                
                let variableCostB4GST = parseFloat(prodList[prod].details[i].variableCostB4GST);
                let variableCostGST = parseFloat(prodList[prod].details[i].variableCostGST);
            
                let costBeforeGST = variableCostB4GST / quantity;
                let costGST = variableCostGST / quantity;
            
                let totalCost = (isNaN(costBeforeGST) ? 0 : costBeforeGST) + (isNaN(costGST) ? 0 : costGST);
            
                mat.details[i] += prodQuantity * totalCost;
                fin.details[i] += quantity * totalCost;
            }
        
            for (let i = 1; i <= 3; i++) {
                let quantity = isLessThan === false ? 1 : parseFloat(prodList[prod]?.aggregate[i]?.quantity);
                let prodQuantity = isLessThan === false ? 1 : parseFloat(prodList[prod]?.aggregate[i]?.prod_quantity);
                
                let variableCostB4GST = parseFloat(prodList[prod].aggregate[i].variableCostB4GST);
                let variableCostGST = parseFloat(prodList[prod].aggregate[i].variableCostGST);
            
                let costBeforeGST = variableCostB4GST / quantity;
                let costGST = variableCostGST / quantity;
            
                let totalCost = (isNaN(costBeforeGST) ? 0 : costBeforeGST) + (isNaN(costGST) ? 0 : costGST);
            
                mat.aggregate[i] += prodQuantity * totalCost;
                fin.aggregate[i] += quantity * totalCost;
            }
        }
      


          

          let totalInflowValue = totalInflow;
          let equityCapitalValue = equityCapital;
          let sourcesOfFund = 0;
          data.data[0].sourcesOfFund.fieldLineItemsName.map(item => {
            sourcesOfFund += parseFloat(item.Amount)
            if(item.Description === "Own Fund" || (item.Description==="Family/friends/relatives"&&parseFloat(item['Repayment Terms (Months)'])===0)){
              equityCapitalValue.details['gestPeriod'] += parseFloat(item.Amount);
            }
          })
          totalInflowValue.details['gestPeriod'] = sourcesOfFund
          
          let subTotalVariable = subTotalVariableCost;
          subTotalVariable.details['gestPeriod'] = parseFloat(data.data[0].startupCost.totalDepreciableAssets) + parseFloat(data.data[0].startupCost.totalNonDepreciableAssets) + parseFloat(data.data[0].startupCost.totalIntangiblesAssets) + parseFloat(data.data[0].startupCost.totalDepositsAndAdvancesAssets) + parseFloat(data.data[0].startupCost.initialRawMaterialPurchase) + parseFloat(totalCash.details['gestPeriod'] + data.data[0].startupCost.initialCash);
          let subTotalAssetAcquisitionValue = subTotalAssetAcquisition;
          subTotalAssetAcquisitionValue.details['gestPeriod'] = parseFloat(data.data[0].startupCost.totalDepreciableAssets) + parseFloat(data.data[0].startupCost.totalNonDepreciableAssets) + parseFloat(data.data[0].startupCost.totalIntangiblesAssets) + parseFloat(data.data[0].startupCost.totalDepositsAndAdvancesAssets)
          
          let totalOutflowValue = totalOutflow;
          totalOutflowValue.details['gestPeriod'] =
          (data.data[0].startupCost?.totalDepreciableAssets + data.data[0].startupCost?.totalNonDepreciableAssets+data.data[0].startupCost?.totalIntangiblesAssets +data.data[0].startupCost?.totalDepositsAndAdvancesAssets)+parseFloat(data.data[0].startupCost?.initialRawMaterialPurchase) + totalCash?.details?.gestPeriod ;
          
          //balance sheet calculation begin
          
          let netFixedAssetsValue = netFixedAssets;
          netFixedAssetsValue.details['gestPeriod'] = parseFloat(data.data[0].startupCost.totalDepreciableAssets) + parseFloat(data.data[0].startupCost.totalNonDepreciableAssets) + parseFloat(data.data[0].startupCost.totalIntangiblesAssets);

          let inventoryTurnoverValue = inventoryTurnover;
          inventoryTurnoverValue.details['gestPeriod']=0;

          let accountsReceivableValue = accountsReceivable;
          accountsReceivableValue.details['gestPeriod'] = 0;
          let gstReceivableValue = gstReceivable;
          gstReceivableValue.details['gestPeriod'] = 0;


          let netGSTPayableValue = netGSTPayable;
          netGSTPayableValue.details['gestPeriod'] = 0;

          let currentPortionValue= currentPortion;
          currentPortionValue.details['gestPeriod'] =parseFloat(data?.data?.[0]?.sourcesOfFund?.bankFinancial?.aggregate?.principle[1])+parseFloat(data?.data?.[0]?.sourcesOfFund?.familyFriends?.aggregate?.principle[1])+parseFloat(data?.data?.[0]?.sourcesOfFund?.machinarySuppliers?.aggregate?.principle[1])+parseFloat(data?.data?.[0]?.sourcesOfFund?.mfi?.aggregate?.principle[1])+parseFloat(data?.data?.[0]?.sourcesOfFund?.rawMaterialSup?.aggregate?.principle[1])+parseFloat(data?.data?.[0]?.sourcesOfFund?.others?.aggregate?.principle[1]);
          let incomeTaxValue = incomeTax;
          incomeTaxValue.details['gestPeriod'] = 0;

          let materialInventoryValue = materialInventory;
          materialInventoryValue.details['gestPeriod']=parseFloat(productValue?.rawMaterialProcurement?.initialInputCost)?parseFloat(productValue?.rawMaterialProcurement?.initialInputCost):parseFloat(data?.data?.[0]?.startupCost?.initialRawMaterialPurchase)?parseFloat(data?.data?.[0]?.startupCost?.initialRawMaterialPurchase):0          
          let finishedGoodsInventoryValue = finishedGoodsInventory;
          finishedGoodsInventoryValue.details['gestPeriod']=0;
          let otherLongTermDebtValue = otherLongTermDebt;
          otherLongTermDebtValue.details['gestPeriod'] = totalFundingValue-currentPortionValue.details['gestPeriod'] ;
          let profitAfterTaxValue = profitAfterTax;
          profitAfterTaxValue.details['gestPeriod'] = 0 - totalFixedExpense.details['gestPeriod'];
          let retainedEarningsValue = retainedEarnings;
          retainedEarningsValue.details['gestPeriod'] = 0 - totalFixedCost.details['gestPeriod'];
          let prepaidExpensesValue = prepaidExpenses;
          prepaidExpensesValue.details['gestPeriod'] = 0;

          let accruedExpensesValue = accruedExpenses;
          accruedExpensesValue.details['gestPeriod'] = 0;
          
          let inputCreditClaim = inputCredit;
          inputCreditClaim.details['gestPeriod']= !isNaN(parseFloat(productValue?.rawMaterialProcurement?.initialInputCostGST)) ? parseFloat(productValue?.rawMaterialProcurement?.initialInputCostGST) : 0;
          let totalCurrentLiabilitiesValue = totalCurrentLiabilities;
          totalCurrentLiabilitiesValue.details['gestPeriod'] = currentPortionValue.details['gestPeriod'] ;


          let accountsPayableValue = accountsPayable;
          let repaymentValue = repayment;
          let totalNetGSTPaidValue = totalNetGSTPaid;
          let totalSales = totalSalesRevenue;
          let totalVariable = totalVariableCost;
          let totalnetGSTOnSale = netGSTOnSale;
          let totalGrossProfit = grossProfit;
          let incometaxPaidValue = incometaxPaid;
          let totalTaxPaid = taxPaid;
          let actualTaxPaid = JSON.parse(JSON.stringify(taxPaid));
          let additonalMachineValue = additonalMachine;
          let openingBalanceValue = openingBalance;
          let closingBalanceValue = closingBalance;
          let totalCurrentAssetsValue = totalCurrentAssets;
          let totalAssetsValue = totalAssets;
          let taxRateYear1 = data.data[0].businessInfoid.taxRateYear1 ? data.data[0].businessInfoid.taxRateYear1 : 0;
          let taxRateYear2 = data.data[0].businessInfoid.taxRateYear2 ? data.data[0].businessInfoid.taxRateYear2 : 0;
          let taxRateYear3 = data.data[0].businessInfoid.taxRateYear3 ? data.data[0].businessInfoid.taxRateYear3 : 0;


          for (let i = 1; i <= 12; i++) {
            
            additonalMachineValue.details[i] = parseFloat(data.data[0].startupCost.whichMonth) == i ? parseFloat(data.data[0].startupCost.totalCost) : 0;
            totalNetGSTPaidValue.details[i] = parseFloat(productValue?.salesProjection?.totalMonthlyValues?.details[i].gst_collected_on_sales)-(!isNaN(parseFloat(productValue?.rawMaterialProcurement?.monthlyInputGSTPaid?.[i])) ? parseFloat(productValue.rawMaterialProcurement.monthlyInputGSTPaid[i]) : 0)-(i===1?(!isNaN(parseFloat(productValue?.rawMaterialProcurement?.initialInputCostGST)) ? parseFloat(productValue.rawMaterialProcurement.initialInputCostGST) : 0):0)
            totalSales.details[i] = parseFloat(productValue.salesProjection.totalMonthlySalesRevenue[i]) + parseFloat(productValue.salesProjection.totalMonthlyGSTCollectOnSale[i]);
            let subsidy = parseFloat(data.data[0].sourcesOfFund.whichMonth) == i ? parseFloat(data.data[0].sourcesOfFund.whichMachine) : 0;
            totalInflowValue.details[i] = parseFloat(productValue.salesProjection.totalMonthlyCashInflowOfSales[i]) + parseFloat(productValue.salesProjection.totalMonthlyCashInflowOfSalesGST[i]) + subsidy;
            totalnetGSTOnSale.details[i] = parseFloat(productValue.salesProjection.totalMonthlyGSTCollectOnSale[i]) - parseFloat(productValue.salesProjection.totalMonthlyValues.details[i].variableCostGST);
            salesCommission.details[i] =productValue.salesProjection.fieldProductsName[0].commission.details[i];
            transportTotal.details[i] =productValue.salesProjection.fieldProductsName[0].transport.details[i];
            totalVariable.details[i] = parseFloat(productValue.salesProjection.totalMonthlyValues.details[i].variableCostB4GST) + parseFloat(productValue.salesProjection.totalMonthlyValues.details[i].variableCostGST) + parseFloat(totalnetGSTOnSale.details[i]);
            subTotalVariable.details[i] =
            (!isNaN(parseFloat(productValue?.rawMaterialProcurement?.monthlyInputPurchaseB4GST?.[i])) ?
              parseFloat(productValue?.rawMaterialProcurement?.monthlyInputPurchaseB4GST?.[i]) :
              0) +
            (!isNaN(parseFloat(productValue?.rawMaterialProcurement?.monthlyInputGSTPaid?.[i])) ?
              parseFloat(productValue?.rawMaterialProcurement?.monthlyInputGSTPaid?.[i]) :
              0) +
            parseFloat(totalNetGSTPaidValue.details[i]) +
            parseFloat(productValue.salesProjection.totalMonthlyValues.details[i].commission) +
            parseFloat(productValue.salesProjection.totalMonthlyValues.details[i].transport) 
            + parseFloat(productValue?.salesProjection?.creditCardFeeCashInFlow?.details?.[i]);           
            totalGrossProfit.details[i] = parseFloat(totalSales.details[i]) -( parseFloat(totalVariable?.details[i])+parseFloat(commission?.details[i])+parseFloat(fgTransport?.details[i]))-parseFloat(productValue?.salesProjection?.creditCardFee?.details[i]);   
            repaymentValue.details[i] = parseFloat(data.data[0].sourcesOfFund.bankFinancial.details.principle[i]) + parseFloat(data.data[0].sourcesOfFund.machinarySuppliers.details.principle[i]) + parseFloat(data.data[0].sourcesOfFund.mfi.details.principle[i]) + parseFloat(data.data[0].sourcesOfFund.rawMaterialSup.details.principle[i]) + parseFloat(data.data[0].sourcesOfFund.familyFriends.details.principle[i])
            totalSales.details[i] = parseFloat(productValue.salesProjection.totalMonthlySalesRevenue[i]) + parseFloat(productValue.salesProjection.totalMonthlyGSTCollectOnSale[i]);
            salesCommission.details[i] = productValue.salesProjection.fieldProductsName.reduce((sum, product) => parseFloat(sum) +( parseFloat(product.commission?.details[i]) || 0), 0);
            transportTotal.details[i] = productValue.salesProjection.fieldProductsName.reduce((sum, product) => parseFloat(sum) +( parseFloat(product.transport?.details[i]) || 0), 0);
            totalnetGSTOnSale.details[i] = parseFloat(productValue.salesProjection.totalMonthlyGSTCollectOnSale[i]) - parseFloat(productValue.salesProjection.totalMonthlyValues.details[i].variableCostGST);
            totalVariable.details[i] = parseFloat(productValue.salesProjection.totalMonthlyValues.details[i].variableCostB4GST) + parseFloat(productValue.salesProjection.totalMonthlyValues.details[i].variableCostGST) + parseFloat(totalnetGSTOnSale.details[i]);
            totalGrossProfit.details[i] = parseFloat(totalSales.details[i]) -(parseFloat(totalVariable?.details[i])+parseFloat(commission?.details[i])+parseFloat(fgTransport?.details[i]))
            -parseFloat(productValue?.salesProjection?.creditCardFee?.details?.[i]);       
            totalTaxPaid.details[i] = parseFloat((totalGrossProfit.details[i]+otherIncome.details[i] - totalFixedCost.details[i]) * taxRateYear1 / 100) < 0 ? 0 : parseFloat((totalGrossProfit.details[i] +otherIncome.details[i]- totalFixedCost.details[i]) * taxRateYear1 / 100)
            
            actualTaxPaid.details[i] =((parseFloat(totalGrossProfit.details[i]) + otherIncome.details[i]  - totalFixedCost.details[i]) * taxRateYear1 / 100) 
            incometaxPaidValue.details[i] = totalTaxPaid.details[i];
            inputCreditClaim.details[i]=parseFloat(productValue?.salesProjection?.totalMonthlyValues?.details?.[i]?.variableCostGST)-(!isNaN(parseFloat(productValue?.rawMaterialProcurement?.monthlyInputGSTPaid?.[i])) ? parseFloat(productValue.rawMaterialProcurement.monthlyInputGSTPaid[i]) : 0)+ (i>1?parseFloat(inputCreditClaim.details[i-1]):0);
            currentPortionValue.details[i]= parseFloat(currentPortionValue?.details['gestPeriod'])
            -(Object.values(data?.data?.[0]?.sourcesOfFund?.bankFinancial?.details?.principle)?.slice(0,i)?.reduce((init, acc)=>(parseFloat(init)+parseFloat(acc)),0))-(Object.values(data?.data?.[0]?.sourcesOfFund?.others?.details?.principle)?.slice(0,i)?.reduce((init, acc)=>(parseFloat(init)+parseFloat(acc)),0))-(Object.values(data?.data?.[0]?.sourcesOfFund?.mfi?.details?.principle)?.slice(0,i)?.reduce((init, acc)=>(parseFloat(init)+parseFloat(acc)),0))-(Object.values(data?.data?.[0]?.sourcesOfFund?.rawMaterialSup?.details?.principle)?.slice(0,i)?.reduce((init, acc)=>(parseFloat(init)+parseFloat(acc)),0))-(Object.values(data?.data?.[0]?.sourcesOfFund?.familyFriends?.details?.principle)?.slice(0,i)?.reduce((init, acc)=>(parseFloat(init)+parseFloat(acc)),0))-(Object.values(data?.data?.[0]?.sourcesOfFund?.machinarySuppliers?.details?.principle)?.slice(0,i)?.reduce((init, acc)=>(parseFloat(init)+parseFloat(acc)),0))
         }
         totalTaxPaid.details[3] = totalTaxPaid.details[1] + totalTaxPaid.details[2] + totalTaxPaid.details[3];
         totalTaxPaid.details[1] = 0;
         totalTaxPaid.details[2] = 0;
         totalTaxPaid.details[6] = totalTaxPaid.details[4] + totalTaxPaid.details[5] + totalTaxPaid.details[6];
         totalTaxPaid.details[4] = 0;
         totalTaxPaid.details[5] = 0;
         totalTaxPaid.details[9] = totalTaxPaid.details[7] + totalTaxPaid.details[8] + totalTaxPaid.details[9];
         totalTaxPaid.details[7] = 0;
         totalTaxPaid.details[8] = 0;
         totalTaxPaid.details[12] = totalTaxPaid.details[10] + totalTaxPaid.details[11] + totalTaxPaid.details[12];
         totalTaxPaid.details[10] = 0;
         totalTaxPaid.details[11] = 0;

         let subsidyYear1 = parseFloat(data.data[0].sourcesOfFund.whichMonth) <= 12 ? parseFloat(data.data[0].sourcesOfFund.whichMachine) : 0;
         let subsidyYear2 = parseFloat(data.data[0].sourcesOfFund.whichMonth) > 12 && parseFloat(data.data[0].sourcesOfFund.whichMonth) <= 24 ? parseFloat(data.data[0].sourcesOfFund.whichMachine) : 0;
         let subsidyYear3 = parseFloat(data.data[0].sourcesOfFund.whichMonth) > 24 ? parseFloat(data.data[0].sourcesOfFund.whichMachine) : 0;

         closingBalanceValue.details['gestPeriod'] = totalInflowValue.details['gestPeriod'] - totalOutflowValue.details['gestPeriod'];
         totalCurrentAssetsValue.details['gestPeriod'] = closingBalanceValue.details['gestPeriod'] + parseFloat(data.data[0].startupCost.totalDepositsAndAdvancesAssets)+parseFloat(inputCreditClaim.details['gestPeriod'])+accountsReceivableValue.details['gestPeriod']+gstReceivableValue.details['gestPeriod']+parseFloat(materialInventoryValue.details['gestPeriod'])+parseFloat(finishedGoodsInventoryValue.details['gestPeriod']);          
         totalAssetsValue.details['gestPeriod'] = netFixedAssetsValue.details['gestPeriod'] + totalCurrentAssetsValue.details['gestPeriod'];

          for (let i = 1; i <= 12; i++) {
            totalOutflowValue.details[i] =parseFloat(additonalMachineValue.details[i]) + parseFloat(subTotalVariable.details[i])+parseFloat(totalCash.details[i])+parseFloat(totalTaxPaid.details[i])+parseFloat(repaymentValue.details[i]);
            let previousMonth = (i - 1) == 0 ? 'gestPeriod' : i - 1;
            openingBalanceValue.details[i] = closingBalanceValue.details[previousMonth];
            closingBalanceValue.details[i] = openingBalanceValue.details[i] + (totalInflowValue.details[i] - totalOutflowValue.details[i]);
            netFixedAssetsValue.details[i] = netFixedAssetsValue.details[previousMonth] - (nonCash.details[i] + additonalMachineValue.details[i]);
            accountsReceivableValue.details[i] = i > 1 ? (parseFloat(accountsReceivableValue.details[previousMonth]) + parseFloat(productValue.salesProjection.totalMonthlySalesRevenue[i]) - parseFloat(productValue.salesProjection.totalMonthlyCashInflowOfSales[i])) :  parseFloat(productValue.salesProjection.totalMonthlySalesRevenue[i]) - parseFloat(productValue.salesProjection.totalMonthlyCashInflowOfSales[i]);
            gstReceivableValue.details[i] = i > 1 ? (parseFloat(gstReceivableValue.details[previousMonth]) + parseFloat(productValue.salesProjection.totalMonthlyGSTCollectOnSale[i]) - parseFloat(productValue.salesProjection.totalMonthlyCashInflowOfSalesGST[i])) :  parseFloat(productValue.salesProjection.totalMonthlyGSTCollectOnSale[i]) - parseFloat(productValue.salesProjection.totalMonthlyCashInflowOfSalesGST[i]);
            prepaidExpensesValue.details[i] = prepaidExpensesValue.details[previousMonth] + parseFloat(totalCash.details[i]) - parseFloat(cashInterest.details[i]) - parseFloat(cashExcludingInterest.details[i]);
            accruedExpensesValue.details[i] = accruedExpensesValue.details[previousMonth] + parseFloat(cashExcludingInterest.details[i]) + parseFloat(cashInterest.details[i]) -  parseFloat(totalCash.details[i]);
            materialInventoryValue.details[i] = parseFloat(materialInventoryValue.details[previousMonth])+(parseFloat(accountsPayableCalc.details[i])- parseFloat(mat.details[i]));            console.log(i, materialInventoryValue.details[previousMonth],(accountsPayableCalc.details[i],mat.details[i]));
           totalCurrentAssetsValue.details[i] =
            (isNaN(prepaidExpensesValue.details[i]) ? 0 : prepaidExpensesValue.details[i]) +
            (isNaN(closingBalanceValue.details[i]) ? 0 : closingBalanceValue.details[i]) +
            (isNaN(parseFloat(data.data[0].startupCost.totalDepositsAndAdvancesAssets)) ? 0 : parseFloat(data.data[0].startupCost.totalDepositsAndAdvancesAssets)) +
            (isNaN(parseFloat(inputCreditClaim.details[i])) ? 0 : parseFloat(inputCreditClaim.details[i])) +
            (isNaN(parseFloat(accountsReceivable.details[i])) ? 0 : parseFloat(accountsReceivable.details[i])) +
            (isNaN(parseFloat(gstReceivableValue.details[i])) ? 0 : parseFloat(gstReceivableValue.details[i])) +
            (isNaN(parseFloat(materialInventoryValue.details[i])) ? 0 : parseFloat(materialInventoryValue.details[i])) +
            (isNaN(parseFloat(finishedGoodsInventoryValue.details[i])) ? 0 : parseFloat(finishedGoodsInventoryValue.details[i]));
           console.log(totalCurrentAssets);
            totalAssetsValue.details[i] = netFixedAssetsValue.details[i] + totalCurrentAssetsValue.details[i];
            netGSTPayableValue.details[i] = netGSTPayableValue.details[previousMonth] + totalnetGSTOnSale.details[i] - totalNetGSTPaidValue.details[i];
            incomeTaxValue.details[i] = incomeTaxValue.details[previousMonth] + incometaxPaidValue.details[i] - totalTaxPaid.details[i];
            equityCapitalValue.details[i] = equityCapitalValue.details['gestPeriod'];
            profitAfterTaxValue.details[i] = ((totalGrossProfit.details[i] - totalFixedCost.details[i]) - incometaxPaidValue.details[i]);
            retainedEarningsValue.details[i] = 
            (isNaN(parseFloat(retainedEarningsValue.details[previousMonth])) ? 0 : parseFloat(retainedEarningsValue.details[previousMonth])) +
            (isNaN(parseFloat(profitAfterTaxValue.details[i])) ? 0 : parseFloat(profitAfterTaxValue.details[i])) +
            (isNaN(parseFloat(other.details[i])) ? 0 : parseFloat(other.details[i]));
            accountsPayableValue.details[i] = accountsPayableValue.details[previousMonth]+accountsPayableCalc?.details[i]-(!isNaN(parseFloat(productValue?.rawMaterialProcurement?.monthlyInputPurchaseB4GST[i])) ? parseFloat(productValue.rawMaterialProcurement.monthlyInputPurchaseB4GST[i]) : 0)-(!isNaN(parseFloat(productValue?.rawMaterialProcurement?.monthlyInputGSTPaid?.[i])) ? parseFloat(productValue.rawMaterialProcurement.monthlyInputGSTPaid[i]) : 0)+parseFloat(productValue?.salesProjection?.creditCardFee?.details[i])-parseFloat(productValue?.salesProjection?.creditCardFeeCashInFlow?.details[i]);
            totalCurrentLiabilitiesValue.details[i] = accountsPayableValue.details[i] + incomeTaxValue.details[i]+currentPortionValue.details[i];
            otherLongTermDebtValue.details[i] = totalFundingValue-currentPortionValue?.details?.['gestPeriod'];
          }
          
          additonalMachineValue.aggregate[1] = parseFloat(data.data[0].startupCost.whichMonth) <= 12 ? parseFloat(data.data[0].startupCost.totalCost) : 0;
          additonalMachineValue.aggregate[2] = parseFloat(data.data[0].startupCost.whichMonth) > 12 && parseFloat(data.data[0].startupCost.whichMonth) <= 24 ? parseFloat(data.data[0].startupCost.totalCost) : 0;
          additonalMachineValue.aggregate[3] = parseFloat(data.data[0].startupCost.whichMonth) > 24 ? parseFloat(data.data[0].startupCost.totalCost) : 0;

          for (let i = 1; i <= 3; i++) {
            // totalNetGSTPaidValue.aggregate[i] = parseFloat(productValue.salesProjection.totalYearlySalesRevenue[i]) + parseFloat(productValue.rawMaterialProcurement.yearlyInputGSTPaid[i]);
            salesCommission.aggregate[i] = productValue.salesProjection.fieldProductsName.reduce((sum, product) => parseFloat(sum) +( parseFloat(product.commission?.aggregate[i]) || 0), 0);
            transportTotal.aggregate[i] = productValue.salesProjection.fieldProductsName.reduce((sum, product) => parseFloat(sum) +( parseFloat(product.transport?.aggregate[i]) || 0), 0);
            totalNetGSTPaidValue.aggregate[i] = parseFloat(productValue.salesProjection.totalYearlyGSTCollectOnSale[i]) - (!isNaN(parseFloat(productValue?.rawMaterialProcurement?.yearlyInputGSTPaid[i])) ? parseFloat(productValue.rawMaterialProcurement.yearlyInputGSTPaid[i]) : 0)-(i===1?(!isNaN(parseFloat(productValue?.rawMaterialProcurement?.initialInputCostGST)) ? parseFloat(productValue.rawMaterialProcurement.initialInputCostGST) : 0):0);
            totalSales.aggregate[i] = parseFloat(productValue.salesProjection.totalYearlySalesRevenue[i]) + parseFloat(productValue.salesProjection.totalYearlyGSTCollectOnSale[i]);
            let subsidy = i == 1 ? subsidyYear1 : i == 2 ? subsidyYear2 : subsidyYear3;
            totalInflowValue.aggregate[i] = parseFloat(productValue.salesProjection.totalYearlyCashInFlowOfSales[i]) + parseFloat(productValue.salesProjection.totalYearlyCashInFlowOfSalesGST[i]) + subsidy;
            totalnetGSTOnSale.aggregate[i] = parseFloat(productValue.salesProjection.totalYearlyGSTCollectOnSale[i]) - parseFloat(productValue.salesProjection.totalMonthlyValues.aggregate[i].variableCostGST);
            totalVariable.aggregate[i] = parseFloat(productValue.salesProjection.totalMonthlyValues.aggregate[i].variableCostB4GST) + parseFloat(productValue.salesProjection.totalMonthlyValues.aggregate[i].variableCostGST) + parseFloat(totalnetGSTOnSale.aggregate[i]);
            totalGrossProfit.aggregate[i] = parseFloat(totalSales.aggregate[i]) -( parseFloat(totalVariable?.aggregate[i])+parseFloat(commission?.aggregate[i])+parseFloat(fgTransport?.aggregate[i]))
            -parseFloat(productValue?.salesProjection?.creditCardFee?.aggregate?.[i]); 
            let taxRate = i == 1 ? taxRateYear1 : i == 2 ? taxRateYear2 : taxRateYear3;
            subTotalVariable.aggregate[i] =
            (!isNaN(parseFloat(productValue?.rawMaterialProcurement?.yearlyInputPurchaseB4GST?.[i])) ?
              parseFloat(productValue?.rawMaterialProcurement?.yearlyInputPurchaseB4GST?.[i]) :
              0) +
            (!isNaN(parseFloat(productValue?.rawMaterialProcurement?.yearlyInputGSTPaid?.[i])) ?
              parseFloat(productValue?.rawMaterialProcurement?.yearlyInputGSTPaid?.[i]) :
              0) +
            parseFloat(totalNetGSTPaidValue.aggregate[i]) +
            parseFloat(productValue.salesProjection.totalMonthlyValues.aggregate[i].commission) +
            parseFloat(productValue.salesProjection.totalMonthlyValues.aggregate[i].transport) 
            + parseFloat(productValue?.salesProjection?.creditCardFeeCashInFlow?.aggregate?.[i]); 
            repaymentValue.aggregate[i] = parseFloat(data.data[0].sourcesOfFund.bankFinancial.aggregate.principle[i]) + parseFloat(data.data[0].sourcesOfFund.machinarySuppliers.aggregate.principle[i]) + parseFloat(data.data[0].sourcesOfFund.mfi.aggregate.principle[i]) + parseFloat(data.data[0].sourcesOfFund.rawMaterialSup.aggregate.principle[i]) + parseFloat(data.data[0].sourcesOfFund.familyFriends.aggregate.principle[i])
            totalTaxPaid.aggregate[i] = i == 1 
            ? Object.values(actualTaxPaid?.details).reduce((sum, item) => sum + item, 0)<0?0:parseFloat(Object.values(actualTaxPaid?.details).reduce((sum, item) => sum + item, 0)) 

            : (() => {
                const grossProfit = parseFloat(totalGrossProfit.aggregate[i]);
                const otherValue = parseFloat(other.aggregate[i]);
                const fixedCost = parseFloat(totalFixedCost.aggregate[i]);
                const taxAmount = (grossProfit + otherValue - fixedCost) * taxRate / 100;
                return taxAmount < 0 ? 0 : taxAmount;
            })();
                        inputCreditClaim.aggregate[i]=parseFloat(productValue?.salesProjection?.totalMonthlyValues?.aggregate?.[i]?.variableCostGST)-(!isNaN(parseFloat(productValue?.rawMaterialProcurement?.yearlyInputGSTPaid[i])) ? parseFloat(productValue.rawMaterialProcurement.yearlyInputGSTPaid[i]) : 0)+ (i>1?parseFloat(inputCreditClaim.aggregate[i-1]):0);
            incometaxPaidValue.aggregate[i] = totalTaxPaid.aggregate[i];

          }

          for (let i = 1; i <= 3; i++) {
            totalOutflowValue.aggregate[i] = subTotalVariable.aggregate[i]+totalCash.aggregate[i]+totalTaxPaid.aggregate[i]+repaymentValue.aggregate[i]+parseFloat(additonalMachineValue.aggregate[i]);
            openingBalanceValue.aggregate[i] = (i == 1 ? openingBalanceValue.details[1] : parseFloat(closingBalanceValue.aggregate[i - 1]));
            closingBalanceValue.aggregate[i] = parseFloat(openingBalanceValue.aggregate[i]) + (parseFloat(totalInflowValue.aggregate[i]) - parseFloat(totalOutflowValue.aggregate[i]));
            netFixedAssetsValue.aggregate[i] = (i == 1 ? netFixedAssetsValue.details[12] : netFixedAssetsValue.aggregate[i - 1] - nonCash.aggregate[i] + additonalMachineValue.aggregate[i]);
            prepaidExpensesValue.aggregate[i] = (i == 1 ? prepaidExpensesValue.details[12] : prepaidExpensesValue.aggregate[i - 1] + parseFloat(totalCash.aggregate[i-1]) - parseFloat(cashInterest.aggregate[i - 1]) - parseFloat(cashExcludingInterest.aggregate[i - 1]));
            accruedExpensesValue.aggregate[i] = (i == 1 ? accruedExpensesValue.details[12] : accruedExpensesValue.aggregate[i - 1] + parseFloat(cashExcludingInterest.aggregate[i - 1]) + parseFloat(cashInterest.aggregate[i - 1]) -  parseFloat(totalCash.aggregate[i-1]));
            accountsReceivableValue.aggregate[i] = i > 1 ? (parseFloat(accountsReceivableValue.aggregate[i-1]) + parseFloat(productValue.salesProjection.totalYearlySalesRevenue[i]) - parseFloat(productValue.salesProjection.totalYearlyCashInFlowOfSales[i])) :  parseFloat(productValue.salesProjection.totalYearlySalesRevenue[i]) - parseFloat(productValue.salesProjection.totalYearlyCashInFlowOfSales[i]);
            gstReceivableValue.aggregate[i] = i > 1 ? (parseFloat(gstReceivableValue.aggregate[i-1]) + parseFloat(productValue.salesProjection.totalYearlyGSTCollectOnSale[i]) - parseFloat(productValue.salesProjection.totalYearlyCashInFlowOfSalesGST[i])) :  parseFloat(productValue.salesProjection.totalYearlyGSTCollectOnSale[i]) - parseFloat(productValue.salesProjection.totalYearlyCashInFlowOfSalesGST[i]);
            totalCurrentAssetsValue.aggregate[i] =  prepaidExpensesValue.aggregate[i] + closingBalanceValue.aggregate[i] + parseFloat(data.data[0].startupCost.totalDepositsAndAdvancesAssets);
            materialInventoryValue.aggregate[i] = (i === 1 ? materialInventoryValue?.details[12] :
             + (
              accountsPayableCalc.aggregate[i] - mat.aggregate[i] + materialInventoryValue.aggregate[i - 1]
            ));
            finishedGoodsInventoryValue.aggregate[i] = 

            (i === 1 ? 
                finishedGoodsInventoryValue.details[12] :( finishedGoodsInventoryValue.aggregate[i - 1]
                  + mat.aggregate[i] - fin.aggregate[i]))
                  totalCurrentAssetsValue.aggregate[i] = i===1?totalCurrentAssetsValue?.details[12]:
                  (isNaN(prepaidExpensesValue.aggregate[i]) ? 0 : prepaidExpensesValue.aggregate[i]) +
                  (isNaN(closingBalanceValue.aggregate[i]) ? 0 : closingBalanceValue.aggregate[i]) +
                  (isNaN(parseFloat(data.data[0].startupCost.totalDepositsAndAdvancesAssets)) ? 0 : parseFloat(data.data[0].startupCost.totalDepositsAndAdvancesAssets)) +
                  (isNaN(parseFloat(inputCreditClaim.aggregate[i])) ? 0 : parseFloat(inputCreditClaim.aggregate[i])) +
                  (isNaN(parseFloat(accountsReceivable.aggregate[i])) ? 0 : parseFloat(accountsReceivable.aggregate[i])) +
                  (isNaN(parseFloat(gstReceivableValue.aggregate[i])) ? 0 : parseFloat(gstReceivableValue.aggregate[i]));
                   totalAssetsValue.aggregate[i] = netFixedAssetsValue.aggregate[i] + totalCurrentAssetsValue.aggregate[i];
            netGSTPayableValue.aggregate[i] = (i == 1 ? netGSTPayableValue.details[12] : netGSTPayableValue.aggregate[i - 1] + totalnetGSTOnSale.aggregate[i] - totalNetGSTPaidValue.aggregate[i]); 
            incomeTaxValue.aggregate[i] = (i == 1 ? incomeTaxValue.details[12] : incomeTaxValue.aggregate[i - 1] + incometaxPaidValue.aggregate[i] - totalTaxPaid.aggregate[i]);
            totalCurrentLiabilitiesValue.aggregate[i] = (i == 1 ? totalCurrentLiabilitiesValue.details[12] : netGSTPayableValue.aggregate[i] + incomeTaxValue.aggregate[i]);
            equityCapitalValue.aggregate[i] = equityCapitalValue.details['gestPeriod'];
            profitAfterTaxValue.aggregate[i] = ((totalGrossProfit.aggregate[i] - totalFixedCost.aggregate[i]) - incometaxPaidValue.aggregate[i]);
            retainedEarningsValue.aggregate[i] = i>1?retainedEarningsValue.aggregate[i-1] + profitAfterTaxValue.aggregate[i]+other.aggregate[i]: retainedEarningsValue.details[12];
            accountsPayableValue.aggregate[i] = i>1?accountsPayableValue?.aggregate?.[i-1]+accountsPayableCalc?.aggregate[i]-(!isNaN(parseFloat(productValue?.rawMaterialProcurement?.yearlyInputPurchaseB4GST[i])) ? parseFloat(productValue.rawMaterialProcurement.yearlyInputPurchaseB4GST[i]) : 0)-(!isNaN(parseFloat(productValue?.rawMaterialProcurement?.yearlyInputGSTPaid[i])) ? parseFloat(productValue.rawMaterialProcurement.yearlyInputGSTPaid[i]) : 0)+parseFloat(productValue?.salesProjection?.creditCardFee?.aggregate[i])-parseFloat(productValue?.salesProjection?.creditCardFeeCashInFlow?.aggregate[i]):accountsPayableValue.details[12];
            currentPortionValue.aggregate[i] = 
            i===3?(parseFloat(currentPortionValue.aggregate[2]))-
            (parseFloat(data?.data?.[0]?.sourcesOfFund?.bankFinancial?.aggregate?.principle[i] ?? 0) +
            parseFloat(data?.data?.[0]?.sourcesOfFund?.familyFriends?.aggregate?.principle[i] ?? 0) +
            parseFloat(data?.data?.[0]?.sourcesOfFund?.machinarySuppliers?.aggregate?.principle[i] ?? 0) +
            parseFloat(data?.data?.[0]?.sourcesOfFund?.rawMaterialSup?.aggregate?.principle[i] ?? 0) +
            parseFloat(data?.data?.[0]?.sourcesOfFund?.mfi?.aggregate?.principle[i] ?? 0) +
            parseFloat(data?.data?.[0]?.sourcesOfFund?.others?.aggregate?.principle[i] ?? 0)): 
            
            (parseFloat(data?.data?.[0]?.sourcesOfFund?.bankFinancial?.aggregate?.principle[i+1] ?? 0) +
            parseFloat(data?.data?.[0]?.sourcesOfFund?.familyFriends?.aggregate?.principle[i+1] ?? 0) +
            parseFloat(data?.data?.[0]?.sourcesOfFund?.machinarySuppliers?.aggregate?.principle[i+1] ?? 0) +
            parseFloat(data?.data?.[0]?.sourcesOfFund?.rawMaterialSup?.aggregate?.principle[i+1] ?? 0) +
            parseFloat(data?.data?.[0]?.sourcesOfFund?.mfi?.aggregate?.principle[i+1] ?? 0) +
            parseFloat(data?.data?.[0]?.sourcesOfFund?.others?.aggregate?.principle[i+1] ?? 0));
            console.log(data?.data?.[0]?.sourcesOfFund)
               
            totalCurrentLiabilitiesValue.aggregate[i] = accountsPayableValue.aggregate[i] + incomeTaxValue.aggregate[i]+currentPortionValue.aggregate[i]
            otherLongTermDebtValue.aggregate[i] = i===1?(otherLongTermDebtValue.details[11]-currentPortionValue?.aggregate[i]):(otherLongTermDebtValue.aggregate[i - 1] - currentPortionValue.aggregate[i]);
         console.log(i,totalCurrentAssetsValue, totalCurrentLiabilitiesValue)
          }
inventoryTurnoverValue.aggregate[1] = totalVariableCost?.aggregate[1] / (
    (
        Object.values(materialInventoryValue?.details)
        ?.reduce((sum, total) => sum + total, 0)-materialInventoryValue?.details?.gestPeriod +
        Object.values(finishedGoodsInventoryValue?.details)
            ?.reduce((sum, total) => sum + total, 0)-finishedGoodsInventoryValue?.details?.gestPeriod

    ) / 12
)    
console.log(totalVariableCost?.aggregate[1])

// +Object.values(finishedGoodsInventoryValue?.details?.reduce((sum, item)=>sum+item,0)))/12)
          inventoryTurnoverValue.aggregate[2]=totalVariableCost?.aggregate?.[1]/((materialInventoryValue?.details?.[12]+finishedGoodsInventoryValue?.details?.[12]+materialInventoryValue?.aggregate?.[2]+finishedGoodsInventoryValue?.aggregate?.[2])/2)
          inventoryTurnoverValue.aggregate[3]=totalVariableCost?.aggregate?.[2]/((materialInventoryValue?.aggregate?.[2]+finishedGoodsInventoryValue?.aggregate?.[2]+materialInventoryValue?.aggregate?.[3]+finishedGoodsInventoryValue?.aggregate?.[3])/2)
           

          
          setTotalInflow(totalInflowValue);
          setTotalNetGSTPaid(totalNetGSTPaidValue);
          setTotalSalesRevenue(totalSales);
          setNetGSTOnSale(totalnetGSTOnSale);
          setTotalVariableCost(totalVariable);
          setGrossProfit(totalGrossProfit);
          // setTaxPaid(incometaxPaidValue);
          setTaxPaid(totalTaxPaid);
          setAdditonalMachine(additonalMachineValue);
          setSubTotalVariableCost(subTotalVariable);
          setRepayment(repaymentValue);
          setTotalOutflow(totalOutflowValue);
          setOpeningBalance(openingBalanceValue);
          setClosingBalance(closingBalanceValue);
          //balancesheet 
          setNetFixedAssets(netFixedAssetsValue);
          setPrepaidExpenses(prepaidExpensesValue);
          setAccruedExpenses(accruedExpensesValue);
          setAccountsReceivable(accountsReceivableValue);
          setGstReceivable(gstReceivableValue);
          setTotalCurrentAssets(totalCurrentAssetsValue);
          setTotalAssets(totalAssetsValue);
          setNetGSTPayable(netGSTPayableValue);
          setIncomeTax(incomeTaxValue);
          setTotalCurrentLiabilities(totalCurrentLiabilitiesValue);
          setOtherLongTermDebt(otherLongTermDebtValue);
          setEquityCapital(equityCapitalValue);
          setProfitAfterTax(profitAfterTaxValue);
          setRetainedEarnings(retainedEarningsValue);
          setInputCredit(inputCreditClaim);
          setAccountsPayable(accountsPayableValue);
          setCurrentPortion(currentPortionValue);
          setMaterialInventory(materialInventoryValue);
          setFinishedGoodsInventory(finishedGoodsInventoryValue);
          setInventoryTurnover(inventoryTurnoverValue);
        })
        .catch((err) => {
          setIsLoader(false);
        });
    }
  }, []);


  if (isLoader) {
    return (
      <Loader></Loader>
    );
  }

  return (
    <>
      <h5>Ratio Analysis</h5>
      <div className="table-responsive">
        <table className="table table-bordered">
          <thead className="table-light">
            <tr>
              <th className="text-center md-width">Description</th>
              <th className="text-center md-width">Year 1</th>
              <th className="text-center md-width">Year 2</th>
              <th className="text-center md-width">Year 3</th>
            </tr>
          </thead>
          <tbody className="table-border-bottom-0">
            <tr>
              <td colSpan="4"><strong>Short Term Liquidity Ratio</strong></td>
            </tr>
            <tr>
              <td>Current Ratio</td>
              <td className="text-right">{(totalCurrentAssets.aggregate[1] / totalCurrentLiabilities.aggregate[1]).toFixed(2)}</td>
              <td className="text-right">{(totalCurrentAssets.aggregate[2] / totalCurrentLiabilities.aggregate[2]).toFixed(2)}</td>
              <td className="text-right">{(totalCurrentAssets.aggregate[3] / totalCurrentLiabilities.aggregate[3]).toFixed(2)}</td>
            </tr>
            <tr>
              <td>Acid Test/Quick Ratio</td>
              <td className="text-right">{((totalCurrentAssets.details[12]-materialInventory?.details?.[12]- finishedGoodsInventory?.details?.[12]) / totalCurrentLiabilities.aggregate[1])?.toFixed(2)}</td>
              <td className="text-right">{((totalCurrentAssets.aggregate[2]-materialInventory?.aggregate?.[2]- finishedGoodsInventory?.aggregate?.[2])  / totalCurrentLiabilities.aggregate[2])?.toFixed(2)}</td>
              <td className="text-right">{((totalCurrentAssets.aggregate[3]-materialInventory?.aggregate?.[3]- finishedGoodsInventory?.aggregate?.[3])  / totalCurrentLiabilities.aggregate[3])?.toFixed(2)}</td>
            </tr>
            <tr>
              <td colSpan="4"><strong>Debt Management Ratio</strong></td>
            </tr>
            <tr>
              <td>Debt equity</td>
              <td className="text-right">{((totalCurrentLiabilities.aggregate[1] + otherLongTermDebt.aggregate[1]) / (equityCapital.aggregate[1] + retainedEarnings.aggregate[1]))?.toFixed(2)}</td>
              <td className="text-right">{((totalCurrentLiabilities.aggregate[2] + otherLongTermDebt.aggregate[2]) / (equityCapital.aggregate[2] + retainedEarnings.aggregate[2]))?.toFixed(2)}</td>
              <td className="text-right">{((totalCurrentLiabilities.aggregate[3] + otherLongTermDebt.aggregate[3]) / (equityCapital.aggregate[3] + retainedEarnings.aggregate[3]))?.toFixed(2)}</td>
            </tr>
            <tr>
              <td>Debt Service Coverage Ratio</td>
              <td className="text-right">{((profitAfterTax.aggregate[1] + fixedCostNonCash.aggregate[1] + fixedCostExcludingInterest.aggregate[1]+ otherIncome.aggregate[1])/(fixedCostExcludingInterest.aggregate[1] + currentPortion.details[1]))?.toFixed(2)}</td>
              <td className="text-right">{((profitAfterTax.aggregate[2] + fixedCostNonCash.aggregate[2] + fixedCostExcludingInterest.aggregate[2]+ otherIncome.aggregate[2])/(fixedCostExcludingInterest.aggregate[2] + currentPortion.details[1]))?.toFixed(2)}</td>
              <td className="text-right">{((profitAfterTax.aggregate[3] + fixedCostNonCash.aggregate[3] + fixedCostExcludingInterest.aggregate[3]+ otherIncome.aggregate[3])/(fixedCostExcludingInterest.aggregate[3] + currentPortion.details[1]))?.toFixed(2)}</td>
            </tr>
            <tr>
              <td colSpan="4"><strong>Operating Ratios</strong></td>
            </tr>
            <tr>
              <td>Inventory turnover</td>
              <td className="text-right">{(inventoryTurnover.aggregate[1]).toFixed(2)}</td>
              <td className="text-right">{(inventoryTurnover.aggregate[2]).toFixed(2)}</td>
              <td className="text-right">{(inventoryTurnover.aggregate[3]).toFixed(2)}</td>
            </tr>
            <tr>
              <td colSpan="4"><strong>Profitability Ratio</strong></td>
            </tr>
            <tr>
              <td>Gross Margin</td>
              <td className="text-right">{getNumericFormat((grossProfit.aggregate[1] / totalSalesRevenue.aggregate[1]) * 100)}%</td>
              <td className="text-right">{getNumericFormat((grossProfit.aggregate[2] / totalSalesRevenue.aggregate[2]) * 100)}%</td>
              <td className="text-right">{getNumericFormat((grossProfit.aggregate[3] / totalSalesRevenue.aggregate[3]) * 100)}%</td>
            </tr>
            <tr>
              <td>Return on asset</td>
              <td className="text-right">{((grossProfit.aggregate[1] - totalFixedExpense.aggregate[1]+otherIncome.aggregate[1]) / totalAssets.aggregate[1]*100).toFixed(0)}%</td>
              <td className="text-right">{((grossProfit.aggregate[2] - totalFixedExpense.aggregate[2]+otherIncome.aggregate[2]) / totalAssets.aggregate[2]*100).toFixed(0)}%</td>
              <td className="text-right">{((grossProfit.aggregate[3] - totalFixedExpense.aggregate[3]+otherIncome.aggregate[3]) / totalAssets.aggregate[3]*100).toFixed(0)}%</td>
            </tr>
            <tr>
              <td>Return on equity</td>
              <td className="text-right">{((grossProfit.aggregate[1] - totalFixedExpense.aggregate[1]+otherIncome.aggregate[1]) / (retainedEarnings?.aggregate[1]+equityCapital.aggregate[1])*100).toFixed(0)}%</td>
              <td className="text-right">{((grossProfit.aggregate[2] - totalFixedExpense.aggregate[2]+otherIncome.aggregate[2]) / (retainedEarnings?.aggregate[2]+equityCapital.aggregate[2])*100).toFixed(0)}%</td>
              <td className="text-right">{((grossProfit.aggregate[3] - totalFixedExpense.aggregate[3]+otherIncome.aggregate[3]) / (retainedEarnings?.aggregate[3]+equityCapital.aggregate[3])*100).toFixed(0)}%</td>
            </tr>
            <tr>
              <td colSpan="4"><strong>Breakeven Level</strong></td>
            </tr>
            <tr>
              <td>Annual Sales Revenue</td>
              <td className="text-right">{getNumericFormat((totalFixedExpense.aggregate[1]/(grossProfit.aggregate[1] / totalSalesRevenue.aggregate[1])))}</td>
              <td className="text-right">{getNumericFormat((totalFixedExpense.aggregate[2]/(grossProfit.aggregate[2] / totalSalesRevenue.aggregate[2])))}</td>
              <td className="text-right">{getNumericFormat((totalFixedExpense.aggregate[3]/(grossProfit.aggregate[3] / totalSalesRevenue.aggregate[3])))}</td>
            </tr>
          </tbody>
        </table>
      </div>

    </>
  );
}

export default RatioAnalysis;
